@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400;1,500&display=swap');

@tailwind base;


h6{
  @apply text-base;
}


h5{
  @apply text-lg;
}

h4{
  @apply text-xl;
}

h3{
  @apply text-2xl;
}

h2{
  @apply text-3xl;
}

h1{
  @apply text-4xl;
}


  h1, h2, h3, h4, h5, h6 {
    @apply font-semibold;
    margin-bottom: 1.2rem;
    @apply font-heading;
  }

  a {
    @apply text-primary;
  }

  body {
    @apply text-base;
    @apply text-dark;
  }
  body p {
    margin-bottom: 1rem;
  }

    ul, ol {
      list-style: revert;
      margin: 2rem ;
    }

    ul li, ol li {
      margin-bottom: 0.8rem ;
    } 

  .background-image{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    width: 100%;
  }

  .background-overlay{
    height: 100%;
    width: 100%;
  }

  .form-floating input {
    margin-bottom: 2rem;
  }

  label{
    font-size: 0.8em;
  }

  .react-multi-carousel-list{
    overflow: visible;
  }
  /***** The important stuff *****/

  button{
    border-radius: 25rem;
    font-size: 0.8em;
  }


@tailwind components;
@tailwind utilities;

