.wp-block-pullquote{
  background-color: theme('colors.gray.100');
  padding: 2rem;
  margin-bottom: 1rem;
  color: theme('colors.dark');
}

.wp-block-cover, .wp-block-pullquote{
  border-radius: 25px;
  overflow: hidden;
}

.wp-block-quote{
  @apply font-black;
  @apply text-3xl;
  padding: 1rem 1rem 1rem 3rem;
  background-color: theme('colors.gray.100');
  border-left: 4px solid theme('colors.primary');
}
.wp-block-cover{
  position: relative;
}

.wp-block-cover__image-background, .wp-block-cover__video-background{
  opacity: 0.6;
}

.wp-block-cover__inner-container{
  position: absolute;
   display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  padding: 1rem;
}

.wp-block-cover__inner-container p{
  flex: 1;
  align-items: center;

}

.has-text-align-center{
  text-align: center;
}

.has-large-font-size{
  @apply font-bold;
  @apply text-xl;
}

.alignleft{
  
} 

.wp-block-quote p {
  margin: 0px;
}

cite, figcaption{
  @apply text-sm;
}

figcaption{
  padding: 0.5rem 0;
  text-align: center;
}

.wp-block-image, .wp-block-button, .wp-block-quote, .wp-block-cover, .wp-block-audio{
  margin-bottom: 2rem;
}

.wp-block-image img {
  margin: 0 auto;
}

.list-style-none{
  list-style: none;
}

.wp-block-gallery, .blocks-gallery-grid{
  display: flex;
  flex-wrap: wrap;
}

.wp-block-gallery .wp-block-image, .blocks-gallery-grid .blocks-gallery-item{
  width: 33.3%;
  padding: 5px;
  list-style: none;
}

.wp-block-button a {
  @apply bg-primary;
  padding: 15px 20px;
  border-radius: 25px;
  width: fit-content;
  @apply text-dark;

}

.is-style-outline{
  background: none;
}