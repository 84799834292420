.mobile-nav, #mobile-menu button{
  text-align: left;
  font-size: 1.8rem;
  transition: all .2s ease-in-out;
}

#mobile-menu button{
  margin: 0px;
  padding: 0px;
}

.mobile-nav li a, #mobile-menu li, #mobile-menu button{
  color: black;
  position: relative;
}

.sub-menu ul li{
  list-style: none;
}

.mobile-nav .sub-menu ul{
margin: 0px;
padding: 10px 0px;
}

.sub-menu{
  overflow: hidden;
}

.sub-menu ul{
  margin: 12px 12px 18px 0px;
}

.sub-menu ul li{
  padding: 12px 22px 0px 22px !important;
  }

.mobile-nav .menulink input:checked + button .sub-menu{
  display: block !important;
}

#mobile-menu li{
margin-top: 0.5rem;
}

.menulink button{
  font-weight: 600;
}

#desktop-menu .menulink{
  padding-top: 8px;
  margin-right: 1rem;
  transition: all 1.5s ease;
  position: relative;
}

#desktop-menu li .sub-menu{
  position: absolute;
  background: white;
  border-radius: 20px;
  left: -2rem;
  width: max-content;
}


.menu-icon{
  width: 35px;
  height: intrinsic;
  border-radius: 25rem;
  margin-right: 12px;
  top: -6px;
  position: relative;
}


.sub-menu a{
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  transition: all 1.5s ease;
}


#desktop-menu li a, #desktop-menu .menulink{
  color: black;
  font-size: 1rem;
  transition: all .2s ease-in-out;
  margin-left: 0em;
}

.mobile-nav .sub-menu li a{
  color: black;
  font-size: 1.2rem;
  transition: all .2s ease-in-out;
}

#desktop-menu li a:hover, .mobile-nav li a:hover {
  color: theme('colors.primary');
  transition: all .2s ease-in-out;
  margin-left: 0.3em;
}

#desktop-menu {
  margin-top: 0.5rem;
}

.logoTypeWrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.textNone {
  left: -100%;
  transition: all 0.5s;
  transform: translateY(1.5);
}

.regular{
  left: 0;
  transition: all 0.5s;
  transform: translateY(-100%);

}